@charset "UTF-8";
*[data-v-6ef1500b] {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.securityManagement[data-v-6ef1500b] {
  width: 100vw;
  height: 100vh;
  position: relative;
  background-image: url(../../static/img/background.26bfca75.jpg);
  background-size: 100% 100%;
}
[data-v-6ef1500b] .el-input__inner {
  background-color: transparent !important;
  border: 0;
  font-family: "PingFangSt";
  color: #fff;
  font-size: 1vw;
  font-style: italic;
  text-shadow: 0 0 12px rgba(0, 106, 255, 0.8);
  padding: 5px;
  font-weight: bold;
  padding-right: 35px;
  white-space: nowrap;
  /* 禁止换行 */
  overflow: hidden;
  /* 超出部分隐藏 */
  text-overflow: ellipsis;
  /* 显示省略号 */
}
[data-v-6ef1500b] .el-select__caret {
  color: #fff !important;
  font-size: 1.563vw !important;
}
[data-v-6ef1500b] .el-input {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.title-logo[data-v-6ef1500b] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 7vh;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  background-image: url(../../static/img/title.24041e47.png);
  background-size: 90% 105%;
  background-position: center;
}
.title-logo div[data-v-6ef1500b] {
  width: 30%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.logo[data-v-6ef1500b] {
  height: 100%;
  padding-left: 3vh;
  position: relative;
}
.logo img[data-v-6ef1500b] {
  width: 12vw;
  height: auto;
}
.title-msg[data-v-6ef1500b] {
  height: 100%;
  padding: 0 1vh 0 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
.securityManagement_centent[data-v-6ef1500b] {
  width: 100%;
  height: calc(100vh - 80px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  padding: 1vh;
}
.center_Item[data-v-6ef1500b] {
  width: 24.5%;
  height: 32%;
}
.block-title[data-v-6ef1500b] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 0.8854vw;
  height: 3vh;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-image: url(../../static/img/itemTitle.3b415dd2.png);
  background-size: 100% 100%;
}
.title-text[data-v-6ef1500b] {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.titleLogo[data-v-6ef1500b] {
  width: 16px;
  margin-left: 1vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 7vh;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  align-items: center;
}
.titleLogo img[data-v-6ef1500b] {
  width: 16px;
  height: 16px;
}
.titleText[data-v-6ef1500b] {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #fff;
  margin-left: 15px;
  font-weight: 500;
  font-size: 15px;
}
.titleThoverHand[data-v-6ef1500b] {
  margin-right: 1vh;
  height: 70%;
}
.main[data-v-6ef1500b] {
  height: 90%;
  padding: 1vh;
  overflow: hidden;
}
.table-main-blueLine[data-v-6ef1500b] {
  height: 100%;
  overflow: hidden;
  padding: 0 1vh;
}
.table-main-blueLine > div > div[data-v-6ef1500b] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  border-bottom: 1px solid #206ed4;
  height: 4vh;
  padding: 1vh;
  color: #fff;
}
@font-face {
  font-family: LED;
  src: url(../../static/fonts/Digiface-Regular.af2808d9.ttf);
  unicode-range: U+30-39;
}
.LED[data-v-6ef1500b] {
  font-family: LED;
  color: #3cdcff;
}
.pojectNews[data-v-6ef1500b] {
  width: 100%;
  height: 100%;
}
.pojectNews > div[data-v-6ef1500b] {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAZIAAAAnCAYAAAAl8s6IAAABF0lEQVR4nO3XsUlDARSF4aOkeoUzSEQFCdioOIID2DiiG7wp0iSVg1iFYBuQZ2IOKIHvq29xu59zdvH6/hYA+O4jyfJl9rlIspg6Ov+7fwA4MTdJnsfNsE6ynjoSEgB+cpk9MRESAPa5TPI0FRMhAeAQ80zEREgAONQ8yeO4GVbZiYmQAPAbV0kedmMy+99/ADhB10m2SZaJRQLAcW7HzXCfZGWRAHCsu3EzbC0SABoLIQGgIiQAVIQEgIqQAFAREgAqQgJARUgAqAgJABUhAaAiJABUhASAipAAUBESACpCAkBFSACoCAkAFSEBoCIkAFSEBICKkABQERIAKkICQEVIAKgICQAVIQGgIiQAVIQEgIqQAFD5ApYTI1ZISLhwAAAAAElFTkSuQmCC);
  background-size: 100% 100%;
  margin-bottom: 1.5vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 1vh;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
[data-v-6ef1500b] .el-carousel__item {
  padding: 1.5vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.elItem[data-v-6ef1500b] {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 47%;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.elItemImageBox[data-v-6ef1500b] {
  width: 40%;
}
.elItemImageBox img[data-v-6ef1500b] {
  width: 100%;
  height: 100%;
}
.LBtxt[data-v-6ef1500b] {
  width: 55%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.pojList[data-v-6ef1500b] {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 1vh;
  z-index: 9;
}
.hideBox[data-v-6ef1500b] {
  height: 91%;
  width: 17.1979vw;
  background-image: url(../../static/img/pojListBG.1f5810c3.png);
  background-size: 100% 100%;
  float: right;
  margin-top: 9vh;
  padding: 1vh;
}
.hideBox > div[data-v-6ef1500b]:last-child {
  height: 95%;
  overflow: scroll;
  overflow-x: hidden;
}
.hideBox > div[data-v-6ef1500b]:last-child::-webkit-scrollbar {
  width: 4px;
}
.scroll[data-v-6ef1500b] {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 0;
}
.scroll > span[data-v-6ef1500b] {
  width: 16vw;
  padding: 1vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 1.5vh;
  color: white !important;
  font-weight: 400 !important;
}
.scroll > span img[data-v-6ef1500b] {
  margin-right: 1vh;
}
.scroll > span[data-v-6ef1500b]:hover {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAALgAAAAmCAYAAAB3X1H0AAAB1klEQVR4nO3doWuVURjH8e/vYpAZTAOLMjFoMpiMBwUF/wAXBmYdc2UwxCBGxWERuUHrTK5ZVnYPyIJgEgaWMTEYZhMcpvsYrlfm5Rp0L/fdHn6feM4bnvAN70mP6PUZYxG4A1wYd2l2CK0C3Sja3H94bMyHD4FbwI0o+jiBwcwOTDXmgLeqMRNFn4fnnZHvngLXgMuO246SKFoF7gP39p8PAz8JPAdOA1eiaHey45kdXBQ9AlCNZ8OzDnAJWAG+ATej6Ec745k14i5wRjVWAESv/wJ4F0Uv253LrBmqMQVsAOui148oUttDmTVJNaaBrdFHplkKUfQVmHbglpoDt9QcuKXmwC01B26pOXBLzYFbag7cUnPglpoDt9QcuKXmwC01B26pOXBLzYFbag7cUnPglpoDt9QcuKXmwC01B26pOXBLzYFbag7cUusA66pxve1BzJqkGjPApw7QBd6oxu12RzJrhmpcBd4DXf3a8HCRwUaH78ByFI1d+2B22KnGEvAYmI2iteE/+AdgCTgOvFaNE20NaPY/VGNKNV4B54DzUbQGfz4y94AFYBvYUI1Tkx/T7N+pxllgE/gSRfNRtP377i9LqB4w+GVx5HYU7DBYQPVk9OIndJFpihmDpaYAAAAASUVORK5CYII=);
  background-size: 100% 100%;
  color: #1ed2ff !important;
}
.scroll > span:hover > .oneChk[data-v-6ef1500b] {
  display: none;
}
.scroll > span:hover > .twoChk[data-v-6ef1500b] {
  display: block !important;
}
[data-v-6ef1500b] .el-input--small {
  margin: 0.5vh 0;
}
[data-v-6ef1500b] .el-input__inner {
  color: #f1f1f1;
}
.loadshow[data-v-6ef1500b] {
  width: 100% !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end !important;
      -ms-flex-pack: end !important;
          justify-content: end !important;
}
.hertaFont[data-v-6ef1500b] {
  background-image: url(../../static/img/BTNIMG.5418c52d.png);
  background-size: 100% 100%;
  padding: 6px 18px;
}
.herdStyle[data-v-6ef1500b] {
  color: #5ad8eb;
  font-size: 2vh;
}
.pojImg[data-v-6ef1500b] {
  width: 20px;
}
.avueinputTree[data-v-6ef1500b] {
  width: 50% !important;
}
.Nodataavailableatthemoment[data-v-6ef1500b] {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  color: #fff;
}
.Nodataavailableatthemoment img[data-v-6ef1500b] {
  height: 100px;
}
.dian2[data-v-6ef1500b] {
  display: inline-block;
  width: 100%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; /* 限制在两行 */
  overflow: hidden; /* 超出部分隐藏 */
  text-overflow: ellipsis; /* 显示省略号 */
}
.dian1[data-v-6ef1500b] {
  display: inline-block;
  width: 100%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1; /* 限制在两行 */
  overflow: hidden; /* 超出部分隐藏 */
  text-overflow: ellipsis; /* 显示省略号 */
}
.fullScreen[data-v-6ef1500b] {
  width: 40px !important;
  margin-left: 20px;
  font-size: 18px;
  color: #1aa3ed;
}